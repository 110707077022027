<template>
  <div class="d-flex flex-column justify-center align-center collection-header-skeleton">
    <v-skeleton-loader
      class="collection-header-skeleton__image"
      type="image" />

    <v-skeleton-loader
      :width="400"
      class="d-flex justify-center"
      type="heading" />

    <div>
      <v-skeleton-loader
        :width="500"
        type="text" />

      <v-skeleton-loader
        :width="500"
        type="text" />
    </div>

    <v-skeleton-loader
      class="d-flex gap-4"
      type="button@3" />
  </div>
</template>

<script>
export default {
  name: 'CollectionHeaderSkeleton',
};
</script>

<style scoped lang="scss">
   ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__image {
      height: 100%;
    }
    .v-skeleton-loader__heading {
      height: 2rem;
    }
    .v-skeleton-loader__button {
      width: 85px;
      height: 32px;
      border-radius: 20px;
    }
  }
  .collection-header-skeleton {
    gap: 16px;

    &__image {
      width: 218px;
      height: 144px;
      border-radius: 20px;
    }
  }
</style>